.root {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    pointer-events: none;
    color: #2563EB;
    &.active {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: all; } }
