.root {
    border-radius: 12px;
    padding: 24px;
    padding-left: 28px;
    display: flex;
    flex-direction: column;
    .subTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #475569; }
    .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #000000; }
    > .title {
        margin-bottom: 36px; }
    .progress {
        & > p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            margin-bottom: 10px; }
        & > div {
            height: 210px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            height: 100%;
            margin-bottom: 10px;
            & > div {
                flex: 0 1; } } }
    .ideation {
        padding: 60px;
        margin-top: 24px;
        flex: 1;
        width: 100%;
        background: #F5F5F4;
        border: 1px solid #EFF6FF;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 10px;
        p {
            max-width: 600px;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            color: #000; }
        button {
            margin-top: 20px; } } }

.sessionWrapper {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    position: relative;
    p.label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #475569; }
    .menu {
        position: fixed;
        z-index: 5;
        top: 0;
        left: 0;
        width: 56px;
        height: 100vh;
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        transition: 0.3s all ease;
        .close {
            cursor: pointer; } }
    .content {
        background: #fff;
        width: 100%;
        height: 100vh;
        margin-left: 56px;
        overflow: hidden;
        border-radius: 12px;
        position: relative;
        margin-right: 333px;
        > div {
            overflow-y: auto;
            height: 100%;
            padding: 24px;
            display: flex;
            flex-direction: column;
            .ideas {
                margin-top: 24px; }
            .ideation {
                padding: 40px;
                margin-top: 24px;
                flex: 1;
                width: 100%;
                border-radius: 28px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                gap: 10px;
                .title {
                    text-align: center;
                    font-weight: 400;
                    font-size: 36px;
                    line-height: 44px; }
                .description {
                    text-align: center;
                    font-weight: 300;
                    font-size: 22px;
                    line-height: 28px;
                    padding: 0 60px; } }
            .progressBar {
                width: 100%;
                margin: 32px 0 16px;
                & > p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: #64748B;
                    margin-bottom: 4px; }
                & > div {
                    width: 100%;
                    height: 8px;
                    background: #BFDBFE;
                    & > div {
                        height: 100%;
                        background: #1D4ED8;
                        transition: .3s all ease; } } }
            form {
                margin-top: 10px;
                width: 100%;
                .textarea {
                    margin-bottom: 10px;
                    & > div {
                        width: 100%; } } } } }
    .info {
        position: fixed;
        top: 0;
        right: 0;
        width: 333px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100vh;
        .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #0F172A;
            margin-bottom: 8px;
            margin-top: 32px; }
        .desc {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #94A3B8; }
        .helperText {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #2563EB;
            margin-top: 20px;
            margin-bottom: 10px; }
        h1 {
            font-weight: 400;
            font-size: 57px;
            line-height: 64px;
            letter-spacing: -0.25px; }
        h2 {
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            text-transform: uppercase; }
        h3 {
            font-weight: 400;
            font-size: 28px;
            line-height: 36px; }
        .counters {
            display: flex;
            justify-content: space-between; }
        .instructions {
            .text {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: #0F172A; } } } }

@media screen and ( max-width: 900px ) {
    .root {
        .progress {
            & > div {
                flex-direction: column;
                height: unset;
                & > div {
                    width: 100%; } } }
        .ideation {
            padding: 16px; } }

    .sessionWrapper {
        .menu {
            height: 100px;
            justify-content: center; }
        .content {
            margin-left: 0;
            margin-right: 0;
            margin-top: 100px;
            & > div {
                padding: 24px 16px;
                .ideation {
                    padding: 16px;
                    .title {
                        font-size: 28px;
                        line-height: 1.1; } } } }
        .info {
            display: grid;
            grid-template-areas: 'title counters timer' 'instructions instructions instructions' 'example example example';
            grid-template-columns: 50% 1fr 90px;
            grid-template-rows: 68px 1fr 1fr;
            width: 100%;
            height: 100px;
            overflow: hidden;
            padding: 16px 16px 16px 56px;
            align-items: center;
            transition: all 0.3s ease;
            z-index: 1;
            background: #EFF6FF;
            gap: 30px 0;
            .timer {
                grid-area: timer;
                & > p:last-child {
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 36px; } }
            .counters {
                grid-area: counters;
                justify-content: space-around; }
            .title {
                grid-area: title; }
            .instructions {
                grid-area: instructions; }
            .example {
                grid-area: example; }
            .btn {
                grid-area: timer; }
            &.withHover {
                &:hover {
                    height: 500px; } } }
        &.bigFooter {
            flex-direction: column-reverse;
            .info {
                display: flex;
                padding-left: 52px;
                height: unset;
                position: relative;
                .title {
                    margin-top: 20px; }
                button {
                    width: 100%; } }
            .content {
                margin-top: 0; } } } }

@media screen and ( max-width: 600px ) {
    .sessionWrapper {
        flex-direction: column-reverse;
        .menu {
            position: absolute; }
        .content {
            margin-top: 0; }
        .info {
            padding: 16px;
            position: relative;
            grid-template-areas: 'close title timer' 'close counters counters' 'instructions instructions instructions' 'example example example';
            grid-template-columns: 56px 1fr 90px;
            grid-template-rows: 68px 56px 1fr 1fr; } } }
