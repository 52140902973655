.root {
    width: 100%;
    padding: 20px 40px 60px;
    display: flex;
    flex-direction: column;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 44px;
        svg {
            cursor: pointer; } }
    .ideaPast {
        padding: 20px 29px;
        background: #FEFCE8;
        border-radius: 28px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        .section {
            display: flex;
            align-items: flex-start;
            gap: 28px;
            position: relative;
            .message {
                border: 1px solid #E2E8F0;
                background: #fff;
                border-radius: 8px;
                padding: 12px 16px 12px 16px;
                word-break: break-word;
                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    color: #475569; } }
            .avatar {
                display: flex;
                justify-content: center;
                min-width: 48px;
                z-index: 0; }
            &::before {
                position: absolute;
                content: '';
                top: 24px;
                left: 24px;
                width: 2px;
                background: #EAB308;
                height: calc(100% + 32px); }
            &.little {
                align-items: center;
                &::before {
                    top: 16px; } }
            &:last-of-type {
                &::before {
                    display: none; } } }
        .title {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            color: #64748B; }
        .subTitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #0F172A; } }
    .ideaPast2 {
        margin-top: 20px;
        padding: 20px 29px;
        background: #EFF6FF;
        border-radius: 28px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        .section {
            display: flex;
            align-items: center;
            gap: 28px;
            .message {
                border: 1px solid #E2E8F0;
                background: #fff;
                border-radius: 8px;
                padding: 12px 16px 12px 16px;
                word-break: break-word;
                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    color: #475569; } }
            &:last-of-type {
                .avatar {
                    &::before {
                        display: none; } } } }
        .subTitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #0F172A;
            padding-bottom: 20px; } }
    form {
        margin-top: auto;
        padding: 20px 29px;
        border-radius: 28px;
        display: flex;
        gap: 28px;
        align-items: flex-start;
        .avatar {
            padding-top: 34px; }
        .content {
            width: 100%;
            gap: 12px;
            display: flex;
            flex-direction: column;
            .subTitle {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1px;
                color: #0F172A; }
            .submit {
                display: flex;
                height: 40px;
                button {
                    padding: 10px 32px;
                    position: relative;
                    z-index: 2; }
                .alert {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 5px 12px;
                    cursor: pointer;
                    border-radius: 8px;
                    &.success {
                        background: #F0FDF4;
                        color: #16A34A; }
                    &.error {
                        background: #FDEDED;
                        color: #D32F2F; } } } } } }
@media screen and ( max-width: 900px ) {
    .root {
        padding: 20px 15px 60px;
        .header {
            margin-bottom: 22px; }
        .ideaPast {
            padding: 16px;
            gap: 16px;
            .subTitle {
                line-height: 1.2; }
            .section {
                gap: 10px;
                &::before {
                    height: calc(100% + 10px); } } }
        form {
            padding: 16px;
            padding-top: 56px;
            margin-top: auto;
            gap: 10px;
            position: relative;
            .avatar {
                padding-top: 0; }
            .content {
                .subTitle {
                    position: absolute;
                    top: 14px;
                    left: 0;
                    width: 100%;
                    text-align: center; } } } } }
