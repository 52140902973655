.root {
    padding: 24px 28px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    .subTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #475569; }
    .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #000000; }
    .progress {
        margin-top: 16px;
        & > p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            margin-bottom: 10px; }
        & > div {
            height: 210px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            height: 100%;
            margin-bottom: 10px;
            & > div {
                flex: 0 1; } } }
    .ideation {
        padding: 60px;
        margin-top: 24px;
        flex: 1;
        width: 100%;
        background: #F5F5F4;
        border: 1px solid #EFF6FF;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 10px;
        p {
            max-width: 600px;
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            color: #000; }
        button {
            margin-top: 20px; } } }

@media screen and ( max-width: 900px ) {
    .root {
        .progress {
            & > div {
                flex-direction: column;
                height: unset;
                & > div {
                    width: 100%; } } }
        .ideation {
            padding: 16px; } } }
