.root {
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    padding: 24px;
    min-width: 300px;
    .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #64748B;
        margin-bottom: 6px; }
    .subTitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #0F172A; }
    .separator {
        width: 100%;
        height: 1px;
        background: #E2E8F0;
        margin: 24px 0; }
    .idea {
        display: flex;
        gap: 16px;
        .content {
            width: 100%;
            .cocreators {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;
                height: 48px;
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #64748B; }
                & > a {
                    text-decoration: none;
                    & > div {
                        display: flex;
                        justify-content: flex-end;
                        & > div {
                            margin-left: -22px; }
                        .add {
                            text-decoration: none;
                            min-width: 48px;
                            min-height: 48px;
                            max-width: 48px;
                            max-height: 48px;
                            border-radius: 100%;
                            background: #3B82F6;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #fff;
                            cursor: pointer; } } } } } } }
