.root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    background: #fff;
    .content {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
        gap: 24px;
        max-width: 500px;
        padding: 0 22px 0 54px;
        & > h1 {
            margin-bottom: 8px; }
        & > p:last-of-type {
            margin-bottom: 18px; }
        .button {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 20px;
            p {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.1px;
                color: #2563EB; }
            button {} } }
    .selection {
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        & > div {
            padding: 75px 64px 32px;
            width: 100%; } } }

@media screen and ( max-width: 1100px ) {
    .root {
        .content {
            min-width: 40vw;
            max-width: 40vw;
            padding: 0 24px; } } }

@media screen and ( max-width: 900px ) {
    .root {
        .content {
            & > h1 {
                line-height: 1; } } } }

@media screen and ( max-width: 700px ) {
    .root {
        .content {
            background: #EFF6FF;
            text-align: center;
            max-width: unset;
            min-width: unset;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            gap: 10px;
            z-index: 1;
            padding-top: 15px;
            h1 {
                font-size: 26px;
                margin: 0; }
            p {
                font-size: 14px; }
            p:last-of-type {
                margin: 0; }
            .button {
                align-items: center; } }
        .selection {
            padding-bottom: 260px;
            & > div {
                padding: 48px 20px 0; } } } }

@media screen and ( max-width: 600px ) {
    .root {
        .selection {
            padding-bottom: 275px; } } }

@media screen and ( max-width: 500px ) {
    .root {
        .content {
            h1 {
                font-size: 22px;
                margin: 0; }
            p {
                font-size: 12px; } } } }
