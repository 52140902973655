.root {
    width: 100%;
    padding: 24px 28px;
    .subTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #475569; }
    .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #000000; }
    .categories {
        margin-top: 12px;
        padding: 16px 16px 32px; } }

@media screen and ( max-width: 700px ) {
    .root {
        padding: 12px 10px;
        .categories {
            padding: 16px 0 32px; } } }
