.root {
    background: #fff;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
    padding: 20px;
    & > h1 {
        max-width: 800px;
        text-align: center;
        & > span {
            color: #2563EB; } }
    .cards {
        display: flex;
        align-items: baseline;
        justify-content: center;
        gap: 40px;
        .card {
            padding: 24px;
            background: #EFF6FF;
            border-radius: 24px;
            display: flex;
            flex-direction: column;
            gap: 12px;
            height: auto;
            .img {
                margin-bottom: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 80px;
                border-radius: 12px;
                background: #fff;
                font-weight: 700;
                font-size: 44px;
                line-height: 60px; }
            p {
                font-weight: 700;
                font-size: 34px;
                line-height: 50px;
                color: #000; }
            span {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: #000; }
            label {
                font-size: 18px;
                color: #1975d2; } } }
    button {
        font-size: 24px;
        padding: 20px 100px; } }

@media screen and ( max-width: 1100px ) {
    .root {
        height: 100%;
        gap: 24px;
        .cards {
            gap: 20px;
            flex-direction: column;
            .card {
                height: unset; } } } }

@media screen and ( max-width: 900px ) {
    .root {
        .cards {
            .card {
                padding: 16px;
                gap: 9px;
                .img {
                    margin: 0; } } } } }


@media screen and ( max-width: 700px ) {
    .root {
        h1 {
            font-size: 36px;
            line-height: 40px; }
        .cards {
            .card {
                p {
                    font-size: 24px;
                    line-height: 28px; }
                span {
                    font-size: 14px;
                    line-height: 18px; } } } } }
