.root {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    overflow: hidden;
    border-radius: 100%;
    border: 2px solid #EAB308;
    background: #fff;
    div {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: 0 0 0 0 inset #fff; } } }
