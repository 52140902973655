.card {
    min-width: 344px;
    width: 344px;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #E2E8F0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .profile {
        display: flex;
        max-height: 52px;
        gap: 12px;
        align-items: center;
        .menu {
            margin-left: auto; }
        .avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 52px;
            width: 52px;
            overflow: hidden;
            border-radius: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover; } }
        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #0F172A; }
            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
                color: #64748B; } } }
    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .title {
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            color: #0F172A;
            overflow-wrap: break-word; }
        .desc {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #475569; } }
    .tags {
        display: flex;
        overflow-y: auto;
        max-height: 32px;
        height: 100%;
        .tag {
            background: #DBEAFE;
            border-radius: 8px;
            padding: 6px 12px; } } }

@media screen and ( max-width: 700px ) {
    .card {
        min-width: 280px;
        width: 280px; } }
