.root {
    width: 100%;
    padding: 20px 40px 60px;
    height: unset !important;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 44px;
        svg {
            cursor: pointer; } }
    form {
        display: flex;
        flex-direction: column;
        gap: 32px;
        > div {
            display: flex;
            flex-direction: column;
            gap: 20px;
            &.textarea {
                gap: 4px;
                .counter {
                    align-self: flex-end;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.4px; } }
            .types {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 12px;
                & > div {
                    background: #DBEAFE;
                    border-radius: 8px;
                    cursor: pointer;
                    padding: 6px 12px;
                    font-weight: 300;
                    &.error {
                        box-shadow: 0 0 0 1px #D32F2F; }
                    &.active {
                        color: #fff;
                        background: #1E40AF; } } }
            &.submit {
                flex-direction: row;
                height: 40px;
                button {
                    padding: 10px 32px;
                    position: relative;
                    z-index: 2; }
                .alert {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 5px 12px;
                    cursor: pointer;
                    border-radius: 8px;
                    &.success {
                        background: #F0FDF4;
                        color: #16A34A; }
                    &.error {
                        background: #FDEDED;
                        color: #D32F2F; } } } } } }

@media screen and ( max-width: 700px ) {
    .root {
        padding: 20px 10px 60px; } }
