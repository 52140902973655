.root {
    background: #F0FDF4;
    padding: 16px;
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    // max-width: 394px
    .title {
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        color: #0F172A; }
    button {
        margin-top: 30px; }
    .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #475569; }
    &.sticker {
        border: 1px solid #E2E8F0;
        border-radius: 8px;
        background: #fff;
        button {
            margin-top: 0; }
        .tag {
            margin-bottom: 12px;
            font-weight: 900; }
        .tags {
            display: flex;
            overflow-y: auto;
            margin-top: 30px;
            align-items: center;
            justify-content: space-between;
            height: 100%; } } }


@media screen and ( max-width: 700px ) {
    .root {
        button {
            margin-left: auto; } } }
