.root {
    text-decoration: none;
    button {
        border-radius: 40px;
        padding: 16px 32px;
        line-height: 20px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.1px;
        text-transform: none;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all 0.2s ease;
        &:disabled {
            opacity: 0.7; } }
    &.boxy {
        button {
            border-radius: 16px;
            padding: 18px 20px; } }
    &.blue {}
    &.darkblue {
        button {
            max-height: 40px;
            height: 40px;
            padding: 10px 24px 10px 16px;
            color: #fff;
            background: #1E40AF; } }
    &.cyan {
        button {
            max-height: 40px;
            height: 40px;
            padding: 10px 24px;
            color: #1E3A8A;
            background: #DBEAFE; } }
    &.gray {
        button {
            background: #F5F3FF;
            color: #000; } }
    &.transparent {
        button {
            background: transparent;
            color: #2563EB;
            height: 40px;
            padding: 10px 24px; } } }
