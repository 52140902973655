.root {
    height: 210px;
    min-width: 260px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 20px;
    border-radius: 12px;
    justify-content: space-between;
    .content {
        .count {
            font-weight: 400;
            font-size: 22px;
            line-height: 28px; }
        .type {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px; }
        .progress {
            width: 100%;
            height: 4px;
            margin-top: 12px;
            background: #BFDBFE;
            & > div {
                height: 100%;
                background: #1D4ED8; } } }
    .icon {
        background: #fff;
        border-radius: 100%;
        align-self: flex-end;
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center; }
    &.currentmethodology, &.stickerstypedata {
        background: #DBEAFE;
        // .content
        //     .progress
        //         background: #BFDBFE
        //         & > div
        //             background: #1D4ED8
        .icon {
            color: #1D4ED8; } }
    &.ideas, &.stickerstypestory {
        background: #DCFCE7;
        // .content
        //     .progress
        //         background: #a7cdb5
        //         & > div
        //             background: #15803D
        .icon {
            color: #15803D; } }
    &.ideasfavorite, &.stickerstypeinsight {
        background: #FFEDD5;
        // .content
        //     .progress
        //         background: #dacab5
        //         & > div
        //             background: #FB923C
        .icon {
            color: #FB923C; } }
    &.speeddatings {
        background: #FCE7F3;
        // .content
        //     .progress
        //         background: #decbd6
        //         & > div
        //             background: #BE185D
        .icon {
            color: #BE185D; } }
    &.stickers {
        background: #FFEDD5;
        // .content
        //     .progress
        //         background: #000
        //         & > div
        //             background: #eee
        .icon {
            color: #EA580C; } } }
