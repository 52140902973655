.root {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    .subTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #475569; }
    .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #000000; }
    > .subTitle {
        padding: 24px 0 0 28px; }
    > .title {
        padding-left: 28px;
        margin-bottom: 36px; }
    .filters {
        display: flex;
        gap: 32px;
        border-bottom: 1px solid #E2E8F0;
        margin: 0 28px 24px;
        > div {
            padding: 13px 0;
            cursor: pointer;
            transition: 0.2s all ease;
            &.active {
                color: #3B82F6;
                box-shadow: inset 0 -3px 0 0 #3B82F6; } } }
    .mainChallenge {
        padding: 20px 29px;
        background: #FEFCE8;
        border-radius: 28px;
        display: flex;
        gap: 28px;
        margin: 0 28px;
        align-items: center;
        span {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            color: #64748B; }
        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #0F172A; } }
    .content {
        padding: 16px 28px 32px;
        flex: 1; } }
