.root {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    .img {
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    .content {
        width: 100%;
        background: #fff;
        max-width: 540px;
        padding: 0 64px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
        h3 {
            max-width: 600px;
            font-weight: 300; }
        .counter {
            font-weight: 400;
            font-size: 45px;
            line-height: 52px;
            color: #000000; }
        .btns {
            display: flex;
            gap: 12px;
            align-items: center;
            a {
                text-decoration: none; } } } }

@media screen and ( max-width: 1100px ) {
    .root {
        height: 100vh;
        .img {
            min-width: 50vw;
            img {
                object-fit: contain; } } } }

@media screen and ( max-width: 900px ) {
    .root {
        flex-direction: column;
        .img {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 0;
            img {
                max-height: 50vh; } }
        .content {
            padding: 24px;
            max-width: unset;
            margin-top: auto;
            z-index: 1;
            gap: 12px;
            .counter {
                font-size: 40px; }
            h1 {
                font-size: 30px; }
            h3 {
                font-size: 18px; } } } }

@media screen and ( max-width: 600px ) {
    .root {
        .img {
 }            // display: none
        .content {
            padding: 20px 10px 20px;
            h1 {
                font-size: 26px; }
            h3 {
                font-size: 16px; }
            .btns {
                flex-direction: column;
                a {
                    width: 100%;
                    button {
                        width: 100%; } } } } } }
