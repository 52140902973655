.root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s all ease;
    .card {
        display: flex;
        flex-direction: column;
        padding: 24px;
        background: rgba( 239, 246, 255, 0.85 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 12px );
        -webkit-backdrop-filter: blur( 12px );
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        border-radius: 28px;
        width: 364px;
        .content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            .title {
                font-weight: 400;
                font-size: 24px;
                line-height: 32px; }
            .subTitle {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: #64748B; }
            .deleteInput {
                margin: 32px 0; } }
        .buttons {
            margin-top: 32px;
            align-self: flex-end;
            display: flex;
            gap: 8px; } }
    &.open {
        pointer-events: all;
        opacity: 1; } }

@media screen and ( max-width: 600px ) {
    .root {
        .card {
            width: 300; } } }
