.root {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    // padding: 24px 28px
    .subTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #475569; }
    .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #000000; }
    > .subTitle {
        padding: 24px 0 0 28px; }
    > .title {
        padding-left: 28px;
        margin-bottom: 36px; }
    .filtersWrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #E2E8F0;
        margin: 0 28px 24px;
        .filters {
            display: flex;
            gap: 32px;
            > div {
                padding: 13px 0;
                cursor: pointer;
                transition: 0.2s all ease;
                &.active {
                    color: #3B82F6;
                    box-shadow: inset 0 -3px 0 0 #3B82F6; } } } }
    .content {
        padding: 16px 28px 32px;
        flex: 1; } }
