.root {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    .img {
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
        background-color: #3B6EF2; }
    .content {
        background: #fff;
        width: 100%;
        max-width: 540px;
        padding: 0 64px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 24px;
        .btns {
            display: flex;
            gap: 12px;
            align-items: center; }
        .uiFirebase {
            min-height: 238px;
            width: 100%;
            button {
                max-width: unset !important;
                border: 1px solid #64748B;
                border-radius: 40px;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff !important;
                box-shadow: none !important;
                padding-right: 35px !important;
                span {
                    font-family: 'Readex Pro' !important;
                    font-weight: 500 !important;
                    font-size: 14px !important;
                    color: rgba(0, 0, 0, 0.9) !important; } } } } }

@media screen and ( max-width: 1100px ) {
    .root {
        .img {
            min-width: 50vw;
            img {
                object-fit: contain; } } } }

@media screen and ( max-width: 900px ) {
    .root {
        flex-direction: column;
        .img {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 0;
            img {
                max-height: 50vh; } }
        .content {
            padding: 24px;
            padding-bottom: 0;
            max-width: unset;
            margin-top: auto;
            z-index: 1;
            gap: 12px;
            h1 {
                font-size: 30px; }
            h3 {
                font-size: 18px; } } } }

@media screen and ( max-width: 600px ) {
    .root {
        .img {
 }            // display: none
        .content {
            padding: 20px 10px 0;
            .btns {
                flex-direction: column;
                align-items: flex-end; } } } }
