.root {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    position: relative;
    .mobile {
        display: none;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        height: 60px;
        background: #EFF6FF;
        padding: 10px 15px;
        .burger {
            width: 24px;
            height: 24px;
            cursor: pointer;
            position: relative;
            &::before, &::after {
                content: '';
                position: absolute;
                left: 0;
                height: 2px;
                width: 100%;
                background: #000;
                transition: 0.2s all ease; }
            &::before {
                top: 7px; }
            &::after {
                bottom: 7px; } } }
    .menu {
        position: fixed;
        z-index: 5;
        top: 0;
        left: 0;
        width: 300px;
        height: 100vh;
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        transition: 0.3s all ease;
        .create {
            padding: 24px 0 16px 0;
            margin: 0 16px; }
        .nav {
            overflow-y: auto;
            & > p {
                padding: 16px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #64748B; } }
        .footer {
            margin-top: auto; }
        ul {
            a {
                color: #0F172A;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                text-decoration: none; }
            li {
                color: #64748B;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                padding: 19px;
                border-radius: 100px;
                width: 100%;
                transition: 0.2s all ease;
                gap: 12px;
                display: flex;
                align-items: center;
                cursor: pointer;
                p {
                    color: #0F172A; }
                &:hover {
                    background: #c7dfff; }
                &.active {
                    color: #3B82F6;
                    background: #DBEAFE; } } } }
    .content {
        background: #fff;
        width: 100%;
        height: 100vh;
        margin-left: 300px;
        overflow: hidden;
        border-radius: 12px;
        position: relative;
        &.withfooter {
            overflow-y: auto;
            margin-right: 300px; }
        > div {
            overflow-y: auto;
            height: 100%; } }
    .faq {
        position: fixed;
        top: 0;
        right: 0;
        width: 300px;
        padding: 12px 24px;
        .title {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1px;
            color: #0F172A;
            margin-bottom: 8px;
            margin-top: 32px; }
        .desc {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #94A3B8; }
        button {
            background: #14B8A6;
            border-radius: 12px;
            padding: 6px 26px;
            margin-top: 8px; } } }

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #E2E8F0;
    max-height: 94px;
    height: 100%;
    img {
        height: 100%;
        object-fit: contain;
        cursor: pointer; } }

@media screen and ( max-width: 900px ) {
    .logo {
        max-height: 40px;
        padding-bottom: 0;
        border: 0;
        object-position: left; }
    .root {
        flex-direction: column;
        .mobile {
            display: flex; }
        .menu {
            background: #EFF6FF;
            top: -100vh;
            width: 100vw;
            height: calc( 100vh - 60px );
            padding: 24px 10px;
            .create {
                button {
                    width: 100%; } } }
        .faq {
            position: relative;
            top: unset;
            right: unset;
            width: unset;
            padding: 30px 10px; }
        .content {
            margin: 60px 0 0 0;
            height: calc(100vh - 60px); }
        &.burger {
            .menu {
                top: 60px;
                padding-top: 0;
                .logo {
                    display: none; } }
            .mobile {
                .burger {
                    &::before {
                        transform: rotate(45deg);
                        top: 11px; }
                    &::after {
                        transform: rotate(-45deg);
                        bottom: 11px; } } } } } }
