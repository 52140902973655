.root {
    border-radius: 12px;
    // padding: 24px 28px
    .subTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #475569; }
    .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #000000; }
    > .subTitle {
        padding: 24px 0 0 28px; }
    > .title {
        padding-left: 28px;
        margin-bottom: 36px; }
    .filters {
        margin: 0 28px 24px;
        display: flex;
        border-bottom: 1px solid #E2E8F0;
        gap: 32px;
        > div {
            padding: 13px 0;
            cursor: pointer;
            transition: 0.2s all ease;
            &.active {
                color: #3B82F6;
                box-shadow: inset 0 -3px 0 0 #3B82F6; } } }
    .content {
        .category {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-bottom: 25px;
            .intro {
                padding: 4px 28px;
                display: flex;
                align-items: center;
                max-height: 48px;
                justify-content: space-between;
                button {
                    padding: 10px 24px 10px 16px;
                    background: #2563EB; } }
            .cards {
                display: flex;
                gap: 24px;
                overflow-x: auto;
                padding-left: 28px;
                padding-bottom: 15px;
                .addCard {
                    border-radius: 8px;
                    border: 1px solid #2563EB;
                    min-width: 344px;
                    width: 344px;
                    height: 250px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 16px;
                    button {
                        background: #2563EB;
                        height: 40px; }
                    p {
                        max-width: 150px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        letter-spacing: 0.1px;
                        color: #1E40AF; } } } } } }

@media screen and ( max-width: 700px ) {
    .root {
        > .subTitle {
            padding: 12px 0 0 10px; }
        > .title {
            padding-left: 10px;
            margin-bottom: 18px; }
        .filters {
            margin: 0 10px 12px; }
        .content {
            .category {
                gap: 12px;
                .intro {
                    padding: 4px 10px;
                    max-height: unset; }
                .cards {
                    padding-left: 10px;
                    .addCard {
                        min-width: 280px;
                        width: 280px; } } } } } }
