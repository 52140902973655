.root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    border: 1px solid #77A7F4;
    border-radius: 28px;
    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 60px; }
    .subTitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        max-width: 320px;
        text-align: center; }
    .btn {} }
