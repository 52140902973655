* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

input, textarea, button {
  outline: none;
  border: 0; }

html {
  background: #EFF6FF;
  font-family: 'Readex Pro', sans-serif; }

h1 {
  font-weight: 700;
  font-size: 44px;
  line-height: 50px; }


.firebaseui-container, .firebaseui-card-content {
  margin: 0 !important;
  padding: 0 !important;
  max-width: unset !important; }
// .firebaseui-idp-facebook > .firebaseui-idp-icon-wrapper
//   border-radius: 100%
//   overflow: hidden
//   background: blue
//   img
//     transform: scale(1.15)

ul {
  list-style: none; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }


.rotate {
  animation: rotate 1s infinite; }


#miro {
  width: 100%;
  height: 100%;
  @media screen and ( max-width: 900px ) {
    height: calc(100vh - 60px); } }
